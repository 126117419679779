<template>
    <div class="page">

        <Head />
        <Sidebar />
        <div class="body-main">
            <breadInfo />
            <div class="container bg flex-1 flex-v">
                <div class="pub-tt m15">
                    <h5 class="bt">
                        <img src="../assets/images/equip_01.png" class="ico" alt="" />设备台账服务
                    </h5>
                    <div class="dix"></div>
                </div>
                <div class="flex-1 flex-v p025">
                    <div class="table flex-1">
                        <el-table :data="tableData" height="100%" v-loading="loading"
                            element-loading-background="rgba(0, 0, 0, 0.8)">
                            <el-table-column prop="id" label="ID"> </el-table-column>
                            <el-table-column prop="name" label="名称"> </el-table-column>
                            <el-table-column :formatter="formatFreeShippingInfo" prop="huiZongFlag" label="是否参与汇总">
                            </el-table-column>
                            <el-table-column prop="brand" label="品牌"> </el-table-column>
                            <el-table-column prop="productionDate" label="出厂日期">
                            </el-table-column>
                            <el-table-column prop="model" label="型号"> </el-table-column>
                            <el-table-column prop="powerCategoryName" label="设备类型">
                            </el-table-column>
                            <el-table-column prop="power" label="功率"> </el-table-column>
                            <el-table-column prop="otherMsg" label="其他设备信息">
                            </el-table-column>
                            <el-table-column prop="ecpectTime" label="预计工作寿命">
                            </el-table-column>
                            <el-table-column prop="alreadyWorkTime" label="已工作时长">
                            </el-table-column>
                            <el-table-column prop="projectName" label="项目">
                            </el-table-column>
                        </el-table>
                    </div>
                    <el-pagination @current-change="handleCurrentChange" v-model:current-page="pageInfo.pageNum"
                        layout="prev, pager, next, jumper,total" :total="pageInfo.total" :page-size="pageInfo.pageSize">
                    </el-pagination>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Head from "@/components/head.vue";
import Sidebar from "@/components/sidebar.vue";
import breadInfo from "@/components/breadInfo.vue";
import { equipList } from "@/api/equip";
export default {
    data() {
        return {
            tableData: [],
            pageInfo: {
                total: 0,
                pageSize: 10,
                pageNum: 1,
            },
            loading: false,
        };
    },
    components: {
        Head,
        Sidebar,
        breadInfo,
    },
    created() {
        this.getEquipList();
    },
    mounted() { },
    watch: {},
    methods: {
        //信息转换为是否
        formatFreeShippingInfo(row, column) {
            return row.huiZongFlag == "1"
                ? "是"
                : row.huiZongFlag == "0"
                    ? "否"
                    : row.huiZongFlag;
        },
        handleCurrentChange(val) {
            this.pageInfo.pageNum = val;
            this.getEquipList();
        },
        //获取设备列表
        getEquipList() {
            this.loading = true;
            let param = {
                pageNum: this.pageInfo.pageNum,
                pageSize: this.pageInfo.pageSize,
                projectId: Number(sessionStorage.getItem("projectId")),
                projectName: sessionStorage.getItem("projectName"),
                tenantId: JSON.parse(sessionStorage.getItem("user")).tenantId,
            };
            equipList(param).then((res) => {
                if (res.data.code == 0) {
                    this.pageInfo.total = res.data.total;
                    this.tableData = res.data.rows;
                } else {
                    this.$message.warning(res.data.msg);
                }
                this.loading = false;
            });
        },
    },
};
</script>

<style scoped></style>
